var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
const NewWindow = (_a) => {
    var { unique = '' } = _a, props = __rest(_a, ["unique"]);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", "aria-label": "opens in new window", viewBox: "0 0 24 24", role: "img" }, props),
        React.createElement("path", { fill: "#000", fillRule: "evenodd", d: "M5.92 2.042c-.765.049-1.246.162-1.74.409A3.78 3.78 0 0 0 2.124 5.16C2.013 5.729 2 6.455 2 12c0 6.358.006 6.526.245 7.32.332 1.102 1.333 2.103 2.435 2.435.794.239.962.245 7.32.245s6.526-.006 7.32-.245c1.102-.332 2.105-1.334 2.435-2.435.216-.72.253-1.286.238-3.66l-.013-1.96-.109-.186a1.01 1.01 0 0 0-1.742 0l-.109.186-.026 2.26c-.028 2.418-.041 2.579-.242 2.992-.126.259-.562.686-.834.816-.452.217-.291.212-6.918.212-6.617 0-6.462.005-6.918-.209-.27-.128-.705-.554-.834-.819a3 3 0 0 1-.16-.48c-.063-.269-.068-.785-.068-6.472s.005-6.203.068-6.472a3 3 0 0 1 .16-.48c.118-.242.558-.682.8-.8.413-.201.574-.214 2.992-.242l2.26-.026.192-.113c.756-.444.631-1.561-.202-1.812-.145-.044-.569-.054-2.046-.049q-1.163-.003-2.324.036m8.775.015a1.04 1.04 0 0 0-.567.459c-.091.156-.108.23-.108.484 0 .256.016.328.111.489.125.213.318.375.539.454.117.042.564.054 2.03.055L18.579 4l-3.705 3.71c-2.038 2.041-3.739 3.773-3.779 3.85-.052.099-.074.228-.074.44-.001.258.015.327.112.492.13.222.369.409.603.473.202.054.53.024.709-.066.074-.037 1.804-1.734 3.844-3.771l3.708-3.704.012 1.941.011 1.941.121.197a.998.998 0 0 0 1.718 0l.121-.197V2.7l-.108-.184a1.02 1.02 0 0 0-.582-.461c-.26-.078-6.345-.076-6.595.002", clipRule: "evenodd" })));
};
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
export default NewWindow;
